import React from 'react';
import PropTypes from 'prop-types';

import {
  AccordionSet,
  AwardScroller,
  ClientSlider,
  ContactDetails,
  FormModule,
  GalleryText,
  HeroVideo,
  LogoGrid,
  ImageGallery,
  ServicesGrid,
  StaffSet,
  TestimonialSlider,
  TextContent,
  Values,
} from './modules';

const ModuleZone = ({ modules }) => {
  const component = modules.map((item) => {
    switch (item._type) {
      case 'accordionSet':
        return <AccordionSet key={item._key} {...item} />;
        case 'awardScroller':
        return <AwardScroller key={item._key} {...item} />;
      case 'clientSlider':
        return <ClientSlider key={item._key} {...item} />;
      case 'contactDetails':
        return <ContactDetails key={item._key} {...item} />;
      case 'formModule':
        return <FormModule key={item._key} {...item} />;
      case 'galleryText':
        return <GalleryText key={item._key} {...item} />;
      case 'heroVideo':
        return <HeroVideo key={item._key} {...item} />;
      case 'imageGallery':
        return <ImageGallery key={item._key} {...item} />;
      case 'logoGrid':
        return <LogoGrid key={item._key} {...item} />;
      case 'servicesGrid':
        return <ServicesGrid key={item._key} {...item} />;
      case 'staffSet':
        return <StaffSet key={item._key} {...item} />;
      case 'testimonialSlider':
        return <TestimonialSlider key={item._key} {...item} />;
      case 'textContent':
        return <TextContent key={item._key} {...item} />;
      case 'values':
        return <Values key={item._key} {...item} />;
      default:
        return null;
    }
  });
  return <>{component}</>;
};

ModuleZone.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default ModuleZone;
