import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';

// Hooks & Querys & Context
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line
import { useSiteState } from '../lib/navContext';

// Components
import ModuleZone from '~moduleZone';
import { PageMeta } from '../components/pageMeta';
import Layout from '../components/layout';
import { BlogHeader } from '../components/pageModules/modules';

const ArticleTemplate = ({ pageContext, data: { page, categories } }) => {
  const { homeHeader, setHomeHeader } = useSiteState();

  const { title, postDate, category, pageModules } = page || {};

  useEffect(() => {
    if (homeHeader === true) {
      setHomeHeader(false);
    }
    document.documentElement.style.setProperty(`--docBg`, `#EAE9E7`);
    document.documentElement.style.setProperty(`--docBgAlt`, `#fff`);
    document.documentElement.style.setProperty(`--docText`, `#000`);
    document.documentElement.style.setProperty(`--docNavActive`, `#A7A9AB`);
  }, [setHomeHeader, homeHeader]);

  return (
    <Layout>
      {page.seo && <PageMeta {...page.seo} />}
      <BlogHeader
        currentCategory={category.title}
        categories={categories.edges}
        title={title}
        date={postDate}
      />
      {pageModules && <ModuleZone {...pageModules} />}
      <div className="flex px-gutter mb-20 lg:mb-40 justify-between text-doc-text">
        <div>
          {pageContext.prev != null && (
            <Link to={`/insights/${pageContext.prev.slug.current}`}>Previous</Link>
          )}
        </div>
        <div>
          {pageContext.next != null && (
            <Link to={`/insights/${pageContext.next.slug.current}`}>Next</Link>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ArticleTemplate;

export const pageQuery = graphql`
  query blogPostQuery($slug: String!) {
    page: sanityBlogPost(slug: { current: { eq: $slug } }) {
      title
      author
      postDate(formatString: "d MMM YYYY")
      category {
        title
        slug {
          current
        }
      }
      seo {
        ...SEO
      }
      pageModules: modules {
        ...BlogPostModules
      }
    }
    categories: allSanityBlogCategory {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
  }
`;
 